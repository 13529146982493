import React, { useState, useEffect, useRef } from 'react';
import { Box, Paper } from '@mui/material';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import MessageList from './MessageList';
import UserSettings from '../Settings/UserSettings';
import ChatInput from './ChatInput';
import EmojiPickerWrapper from './EmojiPickerWrapper';
import ChatHeader from './ChatHeader';

const ChatLayout = ({
  darkMode,
  setDarkMode,
  messages,
  isTyping,
  message,
  setMessage,
  sendMessage,
  userName,
  setUserName,
  userAvatar,
  setUserAvatar,
  showEmojiPicker,
  setShowEmojiPicker,
  startVoiceRecognition,
  isListening,
  isSpeaking,
  speechConsent,
  handleChoiceClick,
  toggleSpeechConsent,
}) => {
  const [showUserSettings, setShowUserSettings] = useState(false);
  const messagesEndRef = useRef(null);

  const toggleUserSettings = () => {
    setShowUserSettings(!showUserSettings);
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  console.log('Rendering ChatLayout component');

  return (
    <ErrorBoundary>
      <Box className="flex flex-col h-full w-full justify-center items-center relative">
        <Box id="chat-container" className="flex flex-col w-full max-w-lg h-full bg-white dark:bg-gray-900 border border-gray-300 rounded-lg shadow-lg overflow-hidden">
          <ChatHeader
            darkMode={darkMode}
            setDarkMode={setDarkMode}
            toggleUserSettings={toggleUserSettings}
            userName={userName}
          />
          <Box className="flex-1 flex flex-col relative">
            <Paper className="flex-1 overflow-y-auto bg-white dark:bg-gray-800 p-4 flex flex-col">
              <MessageList messages={messages} isTyping={isTyping} handleChoiceClick={handleChoiceClick} />
              <div ref={messagesEndRef} />
            </Paper>
            {showEmojiPicker && (
              <EmojiPickerWrapper
                setMessage={setMessage}
                setShowEmojiPicker={setShowEmojiPicker}
              />
            )}
          </Box>
          <Box className="flex-shrink-0 w-full p-2">
            <ChatInput
              message={message}
              setMessage={setMessage}
              sendMessage={sendMessage}
              setShowEmojiPicker={setShowEmojiPicker}
              startVoiceRecognition={startVoiceRecognition}
              isListening={isListening}
            />
          </Box>
        </Box>
        <div className={`user-settings-container ${showUserSettings ? 'fade-in' : 'fade-out'} absolute top-12 right-2 w-72 max-w-full z-10 bg-white dark:bg-gray-800 p-4 shadow-lg rounded-lg`}>
          {showUserSettings && (
            <UserSettings
              userName={userName}
              setUserName={setUserName}
              userAvatar={userAvatar}
              setUserAvatar={setUserAvatar}
              speechConsent={speechConsent}
              toggleSpeechConsent={toggleSpeechConsent}
            />
          )}
        </div>
      </Box>
    </ErrorBoundary>
  );
};

export default ChatLayout;
