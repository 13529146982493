//Logic for tracking user activity
import React, { useEffect, useState } from 'react';

const UserActivity = () => {
  const [activity, setActivity] = useState([]);

  useEffect(() => {
    // Simulate fetching user activity data
    const fetchData = async () => {
      const data = [
        { user: 'User1', action: 'Logged in', timestamp: '2024-05-18 10:00 AM' },
        { user: 'User2', action: 'Started chat', timestamp: '2024-05-18 10:05 AM' },
        { user: 'User3', action: 'Logged out', timestamp: '2024-05-18 10:10 AM' },
      ];
      setActivity(data);
    };

    fetchData();
  }, []);

  return (
    <div>
      <h3>Recent User Activity</h3>
      <ul>
        {activity.map((item, index) => (
          <li key={index}>
            {item.user} - {item.action} - {item.timestamp}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserActivity;
