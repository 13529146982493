//message list in the conversation
import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import Message from './Message';

const MessageList = ({ messages, isTyping, handleChoiceClick }) => {
  const messageEndRef = useRef(null);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <Box className="flex flex-col gap-2 max-h-full overflow-y-auto w-full p-2">
      {messages.map((msg, index) => (
        <Message key={index} msg={msg} handleChoiceClick={handleChoiceClick} />
      ))}
      {isTyping && <Typography variant="caption" color="textSecondary">Typing...</Typography>}
      <div ref={messageEndRef} />
    </Box>
  );
};

export default MessageList;
