//message in the conversation
import React from 'react';
import { Box, Typography, Avatar, Button } from '@mui/material';
import { styled } from '@mui/system';

const MessageBox = styled(Box)(({ theme, sender }) => ({
  alignSelf: sender === 'user' ? 'flex-end' : 'flex-start',
  backgroundColor: sender === 'user' ? theme.palette.userMessageBg : theme.palette.botMessageBg,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  animation: 'fadeIn 0.5s',
  display: 'flex',
  flexDirection: 'column',
  alignItems: sender === 'user' ? 'flex-end' : 'flex-start',
  maxWidth: '90%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '90%',
  },
}));

const MessageText = styled(Typography)(({ theme }) => ({
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
}));

const Message = ({ msg, handleChoiceClick }) => (
  <MessageBox sender={msg.sender}>
    <Box display="flex" flexDirection={msg.sender === 'user' ? 'row-reverse' : 'row'} alignItems="center">
      <Avatar>{msg.avatar}</Avatar>
      <Box ml={msg.sender === 'user' ? 2 : 0} mr={msg.sender === 'user' ? 0 : 2}>
        <MessageText variant="body1" dangerouslySetInnerHTML={{ __html: `${msg.name || 'Unknown'}: ${msg.text || ''}` }} />
        <Typography variant="caption" color="textSecondary">{msg.timestamp}</Typography>
      </Box>
    </Box>
    {msg.choices && (
      <Box className="flex flex-col gap-2 mt-2">
        {msg.choices.map((choice, i) => (
          <Button
            key={i}
            variant="contained"
            color="primary"
            onClick={() => handleChoiceClick(choice)}
            className="cursor-pointer"
          >
            {choice}
          </Button>
        ))}
      </Box>
    )}
  </MessageBox>
);

export default Message;
