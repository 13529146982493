import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ThreadItem from './ThreadItem';

const ThreadsList = () => {
    const [threads, setThreads] = useState(JSON.parse(localStorage.getItem('threads')) || []);
    const navigate = useNavigate();

    useEffect(() => {
        const storedThreads = JSON.parse(localStorage.getItem('threads')) || [];
        setThreads(storedThreads);
    }, []);

    const createNewThread = () => {
        const newThread = {
            id: Date.now().toString(),
            name: `Thread ${threads.length + 1}`,
            messages: []
        };
        const updatedThreads = [...threads, newThread];
        setThreads(updatedThreads);
        localStorage.setItem('threads', JSON.stringify(updatedThreads));
        navigate(`/chat/${newThread.id}`);
    };

    return (
        <div>
            <h1>Chat Threads</h1>
            <button onClick={createNewThread}>Create New Thread</button>
            <ul>
                {threads.map((thread) => (
                    <ThreadItem key={thread.id} thread={thread} />
                ))}
            </ul>
        </div>
    );
};

export default ThreadsList;
