import React from 'react';
import { Box, TextField, IconButton, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

const ChatInput = ({ message, setMessage, sendMessage, setShowEmojiPicker, startVoiceRecognition, isListening }) => {
  React.useEffect(() => {
    console.log('ChatInput mounted');
    return () => {
      console.log('ChatInput unmounted');
    };
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  console.log('Rendering ChatInput');

  return (
    <Box component="form" className="flex w-full p-2 bg-white dark:bg-gray-800 border-t border-gray-300 justify-center" onSubmit={(e) => { e.preventDefault(); sendMessage(); }}>
      <TextField
        variant="outlined"
        fullWidth
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Type your message"
        sx={{ 
          width: '98%',
          backgroundColor: 'white',
          borderRadius: '25px'
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={() => setShowEmojiPicker((prev) => !prev)} color="primary">
                <EmojiEmotionsIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={startVoiceRecognition} color="primary" disabled={isListening}>
                <MicIcon />
              </IconButton>
              <IconButton color="primary" type="submit" disabled={isListening}>
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
          style: { borderRadius: '25px' }
        }}
        className="bg-white dark:bg-gray-800"
      />
    </Box>
  );
};

export default ChatInput;
