//Logic for managing bot content through the admin dashboard
import React, { useState } from 'react';

const ContentManagement = () => {
  const [content, setContent] = useState('');
  const [savedContent, setSavedContent] = useState('');

  const handleSave = () => {
    setSavedContent(content);
    // Simulate saving content to a server or database
  };

  return (
    <div className="content-management">
      <h3>Content Management</h3>
      <textarea
        className="content-textarea"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Enter new bot content here..."
      />
      <button className="save-button" onClick={handleSave}>Save Content</button>
      {savedContent && (
        <div className="saved-content">
          <h4>Saved Content:</h4>
          <p>{savedContent}</p>
        </div>
      )}
    </div>
  );
};

export default ContentManagement;
