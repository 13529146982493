import React from 'react';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import SettingsIcon from '@mui/icons-material/Settings';
import { Typography } from '@mui/material';

const ChatHeader = ({ darkMode, setDarkMode, toggleUserSettings, userName }) => (
  <div id="chat-header" className="bg-chatHeaderBg text-chatHeaderColor p-4 flex justify-between items-center border-b border-gray-300 rounded-t-lg shadow-sm">
    <Typography variant="h6" className="font-semibold">
      {`Chat with ${userName} - ${new Date().toLocaleString()}`}
    </Typography>
    <div className="flex items-center space-x-2">
      <button
        type="button"
        className="p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={() => setDarkMode(!darkMode)}
      >
        {darkMode ? <Brightness7Icon className="h-6 w-6 text-white" /> : <Brightness4Icon className="h-6 w-6 text-white" />}
      </button>
      <button
        type="button"
        className="p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={toggleUserSettings}
      >
        <SettingsIcon className="text-2xl text-white hover:text-gray-200" />
      </button>
    </div>
  </div>
);

export default ChatHeader;
