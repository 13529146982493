// src/components/Footer.js
import React from 'react';

const Footer = () => (
  <footer className="flex justify-center items-center p-4 bg-secondary text-white text-sm shadow-inner w-full">
    <div className="text-center">
      <p>© 2024 USA Restaurant Suppliers</p>
      <p>All rights reserved.</p>
    </div>
  </footer>
);

export default Footer;
