// branding/branding.js
import logo from '../public/images/logo.jpg';

const branding = {
  name: "USA Restaurant Suppliers",
  logo: logo, // Use the imported logo
  primaryColor: "#123456",
  secondaryColor: "#123456",
  font: "Arial, sans-serif",
};

export default branding;
