import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import ChatLayout from './ChatLayout';
import { darkTheme, lightTheme } from '../Settings/VisualSettings';
import useChatManager from '../hooks/useChatManager';
import useVoiceManager from '../hooks/useVoiceManager';
import { Buffer } from 'buffer';

// Polyfill for buffer
global.Buffer = Buffer;

const Chat = () => {
    const { threadId } = useParams();
    const navigate = useNavigate();
    const [darkMode, setDarkMode] = useState(false);
    const [userName, setUserName] = useState('User');
    const [userAvatar, setUserAvatar] = useState('U');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [speechConsent, setSpeechConsent] = useState(localStorage.getItem('speechConsent') === 'true');
    const [attachment, setAttachment] = useState(null);

    const speak = async (text) => {
        try {
            const response = await fetch('https://server.shopusars.com/api/nova', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ text })
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            const audioBlob = await response.blob();
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            setIsSpeaking(true);
            audio.play();
            audio.onended = () => {
                setIsSpeaking(false);
                URL.revokeObjectURL(audioUrl);
            };
        } catch (error) {
            console.error('Error fetching audio from Nova:', error);
        }
    };

    const {
        messages,
        setMessages,
        message,
        setMessage,
        isTyping,
        sendMessage,
        handleChoiceClick,
        sendAttachment
    } = useChatManager(threadId, userAvatar, userName, speechConsent, speak);

    const {
        isListening,
        isSpeaking,
        toggleSpeechConsent,
        startListening,
        setIsSpeaking
    } = useVoiceManager(speechConsent, setSpeechConsent, speak, setMessages);

    useEffect(() => {
        if (typeof Notification !== 'undefined' && Notification.permission !== 'granted') {
            Notification.requestPermission();
        }
    }, []);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setAttachment(file);
        }
    };

    const sendFileAttachment = () => {
        if (attachment) {
            sendAttachment(attachment);
            setAttachment(null);
        }
    };

    console.log('Rendering Chat component');
    console.log({ message });

    return (
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <button onClick={() => navigate('/')}>Back to Threads List</button>
            <ChatLayout
                darkMode={darkMode}
                setDarkMode={setDarkMode}
                messages={messages}
                isTyping={isTyping}
                message={message}
                setMessage={setMessage}
                sendMessage={sendMessage}
                userName={userName}
                setUserName={setUserName}
                userAvatar={userAvatar}
                setUserAvatar={setUserAvatar}
                showEmojiPicker={showEmojiPicker}
                setShowEmojiPicker={setShowEmojiPicker}
                startVoiceRecognition={startListening}
                isListening={isListening}
                isSpeaking={isSpeaking}
                speechConsent={speechConsent}
                handleChoiceClick={handleChoiceClick}
                toggleSpeechConsent={toggleSpeechConsent}
            />
            <input type="file" onChange={handleFileUpload} />
            <button onClick={sendFileAttachment} disabled={!attachment}>Send Attachment</button>
        </ThemeProvider>
    );
};

export default Chat;
