// Hook to manage voice features for the chat
import { useState, useEffect } from 'react';

const useVoiceManager = (initialSpeechConsent, updateSpeechConsent, speak, setMessages) => {
    const [isListening, setIsListening] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [speechConsent, setSpeechConsent] = useState(initialSpeechConsent);

    useEffect(() => {
        localStorage.setItem('speechConsent', speechConsent.toString());
    }, [speechConsent]);

    const toggleSpeechConsent = () => {
        const newConsent = !speechConsent;
        setSpeechConsent(newConsent);
        updateSpeechConsent(newConsent); // Use the parameter to update speech consent in the parent component
        const confirmationMessage = {
            sender: 'bot',
            text: newConsent ? 'Voice features have been enabled. You can now speak to me and I will talk back to you!' : 'Voice features have been disabled. You can still type to interact with me.',
            timestamp: new Date().toLocaleTimeString(),
            avatar: 'B',
            name: 'Bot'
        };
        setMessages(prevMessages => [...prevMessages, confirmationMessage]);
    };

    const startListening = () => {
        const recognition = new window.webkitSpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.onresult = (event) => {
            const transcript = Array.from(event.results)
                .map(result => result[0])
                .map(result => result.transcript)
                .join('');
            setMessage(transcript); // Ensure setMessage is correctly handled
        };
        recognition.onend = () => {
            setIsListening(false);
            sendMessage();
        };
        recognition.start();
        setIsListening(true);
    };

    return {
        isListening,
        isSpeaking,
        speechConsent,
        toggleSpeechConsent,
        startListening,
        setIsSpeaking
    };
};

export default useVoiceManager;