// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import branding from '../../branding/branding';

const Header = () => (
  <header className="flex justify-between items-center p-4 bg-secondary text-white shadow-md">
    <img src={branding.logo} alt={`${branding.name} Logo`} className="h-10" />
    <nav className="flex gap-4">
      <Link to="/" className="hover:underline">Chat Bot</Link>
      <Link to="/admin" className="hover:underline">Admin Dashboard</Link>
    </nav>
  </header>
);

export default Header;
