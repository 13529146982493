import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Chat from './components/Chat/Chat';
import ThreadsList from './components/ThreadsList';
import AdminDashboard from './components/Admin/AdminDashboard';
import branding from '../branding/branding';
import './tailwind.css';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <main className="flex-1 flex flex-col items-center justify-center relative w-full bg-gradient-to-br from-gray-300 to-gray-200 p-0 box-border overflow-y-auto">
        <Routes>
          <Route path="/" element={<ThreadsList />} />
          <Route path="/chat/:threadId" element={<Chat />} />
          <Route path="/admin/*" element={<AdminDashboard />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
