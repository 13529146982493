export const sendMessageToAssistant = async (assistantId, message) => {
    try {
        const response = await fetch(`https://server.shopusars.com/api/assistants/send-message/${assistantId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ message })
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Message sent successfully:', data);
        return data;
    } catch (error) {
        console.error('Error sending message:', error);
        throw error;
    }
};

export const sendAttachmentToAssistant = async (assistantId, attachment) => {
    try {
        const formData = new FormData();
        formData.append('attachment', attachment);

        const response = await fetch(`https://server.shopusars.com/api/assistants/send-attachment/${assistantId}`, {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Attachment sent successfully:', data);
        return data;
    } catch (error) {
        console.error('Error sending attachment:', error);
        throw error;
    }
};
