import React from 'react';
import { Link } from 'react-router-dom';

const ThreadItem = ({ thread }) => {
    return (
        <li>
            <Link to={`/chat/${thread.id}`}>{thread.name}</Link>
        </li>
    );
};

export default ThreadItem;
