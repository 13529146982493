// src/components/Settings/VisualSettings.js
import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

export const MessageBox = styled(Box)(({ theme, sender }) => ({
    alignSelf: sender === 'user' ? 'flex-end' : 'flex-start',
    backgroundColor: sender === 'user' ? '#e1f5fe' : '#fce4ec',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    animation: 'fadeIn 0.5s',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
}));

export const MessageText = styled(Box)(({ theme }) => ({
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
}));
