import React, { Suspense } from 'react';
import { Box } from '@mui/material';
import EmojiPickerComponent from 'emoji-picker-react'; // Assuming you are using this library

const EmojiPickerWrapper = ({ setMessage, setShowEmojiPicker }) => {
  const onEmojiClick = (emojiObject) => {
    setMessage(prevMessage => prevMessage + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  return (
    <Box
      style={{
        position: 'absolute',
        bottom: '80px', // Adjust as needed to fit above the chat input
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 10,
        backgroundColor: 'white',
        padding: '8px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <EmojiPickerComponent onEmojiClick={onEmojiClick} />
      </Suspense>
    </Box>
  );
};

export default EmojiPickerWrapper;
