// src/components/Admin/BrandingSettings.js
import React, { useState } from 'react';
import branding from '../../../branding/branding'; // Corrected path
import './BrandingSettings.css';

const BrandingSettings = () => {
  const [primaryColor, setPrimaryColor] = useState(branding.primaryColor);
  const [secondaryColor, setSecondaryColor] = useState(branding.secondaryColor);
  const [fontFamily, setFontFamily] = useState(branding.font);
  const [logoUrl, setLogoUrl] = useState(branding.logo);

  const handleSave = () => {
    // Save the branding settings (you might want to update your state management or server here)
    console.log('Saved Branding Settings:', {
      primaryColor,
      secondaryColor,
      fontFamily,
      logoUrl
    });
  };

  return (
    <div className="branding-settings">
      <h2>Branding Settings</h2>
      <div className="form-group">
        <label>Primary Color:</label>
        <input
          type="color"
          value={primaryColor}
          onChange={(e) => setPrimaryColor(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Secondary Color:</label>
        <input
          type="color"
          value={secondaryColor}
          onChange={(e) => setSecondaryColor(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Font Family:</label>
        <input
          type="text"
          value={fontFamily}
          onChange={(e) => setFontFamily(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Logo URL:</label>
        <input
          type="text"
          value={logoUrl}
          onChange={(e) => setLogoUrl(e.target.value)}
        />
      </div>
      <button className="save-button" onClick={handleSave}>Save</button>
    </div>
  );
};

export default BrandingSettings;
