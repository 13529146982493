// src/components/Settings/UserSettings.js
import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import './UserSettings.css';

const UserSettings = ({ userName, setUserName, userAvatar, setUserAvatar, speechConsent, toggleSpeechConsent }) => {
  const [userNameInput, setUserNameInput] = useState('');
  const [userAvatarInput, setUserAvatarInput] = useState('');

  const handleUserSettingsSubmit = (e) => {
    e.preventDefault();
    setUserName(userNameInput || userName); // Keep the current name if no new input
    setUserAvatar(userAvatarInput || userAvatar); // Keep the current avatar if no new input
    setUserNameInput('');
    setUserAvatarInput('');
  };

  return (
    <Box className="user-settings" sx={{ mt: 2 }}>
      <Typography variant="h6" className="user-settings-title">User Settings Awesome</Typography>
      <form onSubmit={handleUserSettingsSubmit} className="user-settings-form">
        <TextField
          label="Name"
          variant="outlined"
          value={userNameInput}
          onChange={(e) => setUserNameInput(e.target.value)}
          className="user-settings-input"
        />
        <TextField
          label="Avatar"
          variant="outlined"
          value={userAvatarInput}
          onChange={(e) => setUserAvatarInput(e.target.value)}
          className="user-settings-input"
        />
        <Button type="submit" variant="contained" color="primary" className="user-settings-button">Update User Settings</Button>
      </form>
      <Button
        variant="contained"
        color="secondary"
        className="user-settings-voice-button"
        onClick={toggleSpeechConsent}
      >
        {speechConsent ? 'Disable Voice Features' : 'Enable Voice Features'}
      </Button>
    </Box>
  );
};

export default UserSettings;
