import { useState, useEffect } from 'react';
import { sendMessageToAssistant, sendAttachmentToAssistant } from '../Connection/sendMessageToAssistant';

const useChatManager = (assistantId, userAvatar, userName, speechConsent, speak) => {
    const [messages, setMessages] = useState(JSON.parse(localStorage.getItem(`messages_${assistantId}`)) || []);
    const [message, setMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
        localStorage.setItem(`messages_${assistantId}`, JSON.stringify(messages));
        console.log('Messages updated:', messages);
    }, [messages, assistantId]);

    useEffect(() => {
        if (messages.length === 0) {
            const greetingMessage = {
                sender: 'bot',
                text: 'Hello and welcome to our bot! How can I assist you today? Here are some options to get started:',
                timestamp: new Date().toLocaleTimeString(),
                avatar: 'B',
                name: 'Bot',
                choices: [
                    'Tell me about your services.',
                    'I need help with my order.',
                    'Can you recommend a product?',
                    'How can I contact support?'
                ]
            };
            setMessages([greetingMessage]);
        }
    }, []); // Only run on mount

    const handleChoiceClick = async (choice) => {
        const newMessage = { sender: 'user', text: choice, timestamp: new Date().toLocaleTimeString(), avatar: userAvatar, name: userName };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setIsTyping(true);

        try {
            const botMessage = await sendMessageToAssistant(assistantId, choice);
            botMessage.sender = 'bot'; // Ensure sender is set to 'bot'
            botMessage.avatar = 'B'; // Ensure avatar is set for bot
            botMessage.name = 'Bot'; // Ensure name is set for bot
            setMessages(prevMessages => [...prevMessages, botMessage]);
            showNotification('Bot', botMessage.text);
            if (speechConsent) {
                try {
                    await speak(botMessage.text);
                } catch (error) {
                    console.error('Error during speech synthesis:', error);
                }
            }
        } catch (error) {
            console.error('Error sending message:', error);
            const errorMessage = { sender: 'bot', text: `Error: ${error.message}`, timestamp: new Date().toLocaleTimeString(), avatar: 'B', name: 'Bot' };
            setMessages(prevMessages => [...prevMessages, errorMessage]);
            showNotification('Bot', `Error: ${error.message}`);
        }

        setIsTyping(false);
    };

    const sendMessage = async () => {
        if (message.trim() === '') return;

        const newMessage = { sender: 'user', text: message, timestamp: new Date().toLocaleTimeString(), avatar: userAvatar, name: userName };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setMessage('');
        setIsTyping(true);

        try {
            const botMessage = await sendMessageToAssistant(assistantId, message);
            botMessage.sender = 'bot'; // Ensure sender is set to 'bot'
            botMessage.avatar = 'B'; // Ensure avatar is set for bot
            botMessage.name = 'Bot'; // Ensure name is set for bot
            setMessages(prevMessages => [...prevMessages, botMessage]);
            showNotification('Bot', botMessage.text);
            if (speechConsent) {
                try {
                    await speak(botMessage.text);
                } catch (error) {
                    console.error('Error during speech synthesis:', error);
                }
            }
        } catch (error) {
            console.error('Error sending message:', error);
            const errorMessage = { sender: 'bot', text: `Error: ${error.message}`, timestamp: new Date().toLocaleTimeString(), avatar: 'B', name: 'Bot' };
            setMessages(prevMessages => [...prevMessages, errorMessage]);
            showNotification('Bot', `Error: ${error.message}`);
        }

        setIsTyping(false);
    };

    const sendAttachment = async (attachment) => {
        const newMessage = { sender: 'user', text: `Attachment: ${attachment.name}`, timestamp: new Date().toLocaleTimeString(), avatar: userAvatar, name: userName };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setIsTyping(true);

        try {
            const botMessage = await sendAttachmentToAssistant(assistantId, attachment);
            botMessage.sender = 'bot'; // Ensure sender is set to 'bot'
            botMessage.avatar = 'B'; // Ensure avatar is set for bot
            botMessage.name = 'Bot'; // Ensure name is set for bot
            setMessages(prevMessages => [...prevMessages, botMessage]);
            showNotification('Bot', botMessage.text);
            if (speechConsent) {
                try {
                    await speak(botMessage.text);
                } catch (error) {
                    console.error('Error during speech synthesis:', error);
                }
            }
        } catch (error) {
            console.error('Error sending attachment:', error);
            const errorMessage = { sender: 'bot', text: `Error: ${error.message}`, timestamp: new Date().toLocaleTimeString(), avatar: 'B', name: 'Bot' };
            setMessages(prevMessages => [...prevMessages, errorMessage]);
            showNotification('Bot', `Error: ${error.message}`);
        }

        setIsTyping(false);
    };

    const showNotification = (title, body) => {
        if (typeof Notification !== 'undefined' && Notification.permission === 'granted') {
            new Notification(title, { body });
        }
    };

    return {
        messages,
        setMessages,
        message,
        setMessage,
        isTyping,
        sendMessage,
        handleChoiceClick,
        sendAttachment
    };
};

export default useChatManager;
