//Logic for monitoring bot system health
import React, { useEffect, useState } from 'react';

const SystemHealth = () => {
  const [health, setHealth] = useState({});

  useEffect(() => {
    // Simulate fetching system health data
    const fetchData = async () => {
      const data = {
        cpuUsage: '15%',
        memoryUsage: '45%',
        diskSpace: '70GB free of 200GB',
      };
      setHealth(data);
    };

    fetchData();
  }, []);

  return (
    <div>
      <h3>System Health</h3>
      <ul>
        <li>CPU Usage: {health.cpuUsage}</li>
        <li>Memory Usage: {health.memoryUsage}</li>
        <li>Disk Space: {health.diskSpace}</li>
      </ul>
    </div>
  );
};

export default SystemHealth;
