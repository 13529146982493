// src/components/Admin/AdminDashboard.js
import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ContentManagement from './ContentManagement';
import Monitoring from './Monitoring';
import UserActivity from './UserActivity';
import SystemHealth from './SystemHealth';
import BrandingSettings from './BrandingSettings';
import Sidebar from './Sidebar';
import './AdminDashboard.css';

const AdminDashboard = () => {
  return (
    <div className="admin-dashboard">
      <Sidebar />
      <div className="admin-content">
        <Routes>
          <Route path="/" element={<DashboardHome />} />
          <Route path="content-management" element={<ContentManagement />} />
          <Route path="monitoring" element={<Monitoring />} />
          <Route path="user-activity" element={<UserActivity />} />
          <Route path="system-health" element={<SystemHealth />} />
          <Route path="branding-settings" element={<BrandingSettings />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
};

const DashboardHome = () => (
  <div>
    <h2>Welcome to the Admin Dashboard</h2>
    <p>Select an option from the sidebar to get started.</p>
  </div>
);

export default AdminDashboard;
