// src/components/Admin/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';
import branding from '../../../branding/branding'; // Corrected path
import './Sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar" style={{ backgroundColor: branding.primaryColor }}>
      <div className="sidebar-logo">
        <img src={branding.logo} alt={`${branding.name} Logo`} />
      </div>
      <nav className="sidebar-nav">
        <ul>
          <li><Link to="/">Chat Bot</Link></li>
          <li><Link to="/admin">Dashboard</Link></li>
          <li><Link to="/admin/content-management">Content Management</Link></li>
          <li><Link to="/admin/monitoring">Monitoring</Link></li>
          <li><Link to="/admin/user-activity">User Activity</Link></li>
          <li><Link to="/admin/system-health">System Health</Link></li>
          <li><Link to="/admin/branding-settings">Branding Settings</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
